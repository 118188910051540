import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import LinkStyles from './Link.styled';
import ArrowIcon from './ArrowIcon';
import theme from '../../styles/theme';
import CustomUrl from '../../util/url/CustomUrl';

const Link = ({
  to,
  children,
  isExternal = false,
  newTab = false,
  className = '',
  carryQuery = true,
  includeArrowIcon = true,
  ...props
}) => {
  const { search } = useLocation();

  const fullUrl = useMemo(() => {
    if (carryQuery) {
      const pathWithQuery = new CustomUrl(to);
      pathWithQuery.joinQuery(search);

      return pathWithQuery.fullUrl;
    }

    return to;
  }, [to, search, carryQuery]);

  return isExternal ? (
    <a
      href={fullUrl}
      target={newTab ? '_blank' : '_self'}
      className={`${className} link`}
      {...props}
      rel='noopener noreferrer'
    >
      {children}
      {includeArrowIcon && <ArrowIcon />}
    </a>
  ) : (
    <RouterLink to={fullUrl} className={`${className} link`} {...props}>
      {children}
      {includeArrowIcon && <ArrowIcon />}
    </RouterLink>
  );
};

Link.propTypes = {
  to: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  newTab: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]),
  color: PropTypes.string,
};

export default LinkStyles(Link);
